<div class="cards">
  <div class="card-single">
    <div>
      <h1>54</h1>
      <span>Clientes</span>
    </div>
    <div>
      <span class="las la-users"></span>
    </div>
  </div>
  <div class="card-single">
    <div>
      <h1>79</h1>
      <span>Acessos</span>
    </div>
    <div>
      <span class="las la-clipboard"></span>
    </div>
  </div>
  <div class="card-single">
    <div>
      <h1>124</h1>
      <span>Parceiros</span>
    </div>
    <div>
      <span class="las la-shopping-bag"></span>
    </div>
  </div>
  <div class="card-single">
    <div>
      <h1>$6k</h1>
      <span>Ações</span>
    </div>
    <div>
      <span class="lab la-google-wallet"></span>
    </div>
  </div>
</div>

<div class="recent-grid">
  <div class="projects">
    <div class="card">
      <div class="card-header">
        <h3>Novos Restaurantes</h3>
        <button>Ver todos <span class="las la-arrow-right"></span></button>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table width="100%">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Categoria</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Restaurante do João</td>
                <td>Mineiro</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
              </tr>
              <tr>
                <td>MC Donalds</td>
                <td>Fast Food</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
              </tr>
              <tr>
                <td>Recanto do Gaucho</td>
                <td>Churrascaria</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
              </tr>
              <tr>
                <td>Habib's</td>
                <td>Fast Food</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
              </tr>
              <tr>
                <td>Boi Dourado</td>
                <td>Churrascaria</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
              </tr>
              <tr>
                <td>Mineiro</td>
                <td>Self Service</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="customers">
    <div class="card">
      <div class="card-header">
        <h3>Novos Usuários</h3>
        <button>Ver todos <span class="las la-arrow-right"></span></button>
      </div>

      <div class="card-body">
        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>

        <div class="customer">
          <div class="info">
            <img
              src="@/assets/avatar_user.jpeg"
              width="40px"
              height="40px"
              alt=""
            />
            <div>
              <h4>Lewis S. Cunningham</h4>
              <small>CEO Excerpt</small>
            </div>
          </div>
          <div class="contact">
            <span class="las la-user-circle"></span>
            <span class="las la-comment"></span>
            <span class="las la-phone"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
