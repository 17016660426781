import { defineComponent } from "vue";
import Header from "@/components/header/header.vue";
import Navigation from "@/components/sidebar-navigation/navigation.vue";

export default defineComponent({
  name: "App",
  components: {
    cabecalho: Header,
    navigation: Navigation,
  },
  setup() {
    return {};
  },
});
