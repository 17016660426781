
<div class="usuarios-grid">
    <div class="projects">
      <div class="card">
        <div class="card-header">
          <h3>Restaurantes</h3>
        </div>
  
        <div class="card-body">
          <div class="table-responsive">
            <table width="100%">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Cidade</th>
                  <th>Categoria</th>
                  <th>Cliques no mês</th>
                  <th>Status</th>
                  <th>Data de Cadastro</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Restaurante do João</td>
                  <td>São Caetano do Sul</td>
                  <td>Mineiro</td>
                  <td>100</td>
                  <td>
                    <span class="status purple"></span>
                    revisão
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
                <tr>
                  <td>MC Donalds</td>
                  <td>Santo André</td>
                  <td>Fast Food</td>
                  <td>100</td>
                  <td>
                    <span class="status pink"></span>
                    ativo
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Recanto do Gaucho</td>
                  <td>São Bernardo do Campo</td>
                  <td>Churrascaria</td>
                  <td>100</td>
                  <td>
                    <span class="status orange"></span>
                    pendente
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Habib's</td>
                  <td>Diadema</td>
                  <td>Fast Food</td>
                  <td>100</td>
                  <td>
                    <span class="status purple"></span>
                    revisão
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Boi Dourado</td>
                  <td>Mauá</td>
                  <td>Churrascaria</td>
                  <td>100</td>
                  <td>
                    <span class="status pink"></span>
                    ativo
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Mineiro</td>
                  <td>Ribeirão Pires</td>
                  <td>Self Service</td>
                  <td>100</td>
                  <td>
                    <span class="status orange"></span>
                    pendente
                  </td>
                  <td>30/08/2024</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  