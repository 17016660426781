<header>
  <h2>
    <label for="nav-toggle">
      <span class="las la-bars"></span>
    </label>
    Painel
  </h2>

  <div class="search-wrapper">
    <span class="las la-search"></span>
    <input type="search" placeholder="Pesquisa geral" />
  </div>

  <div class="user-wrapper">
    <img src="@/assets/avatar_user.jpeg" width="40px" height="40px" alt="" />
    <div>
      <h4>Mauro Miranda</h4>
      <small>Super admin</small>
    </div>
  </div>
</header>
