<div class="usuarios-grid">
  <div class="projects">
    <div class="card">
      <div class="card-header">
        <h3>Planos</h3>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table width="100%">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Preço</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Semanal</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Fins de semana</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Mensal</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Trimestral</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Semestral</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Anual</td>
                <td>R$ 0,00</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
