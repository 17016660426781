<input type="checkbox" id="nav-toggle" />
<div class="sidebar">
  <div class="sidebar-brand">
    <h2>
      <span class="lab la-accusoft"></span>
      <span>{{ companyName }}</span>
    </h2>
  </div>

  <div class="sidebar-menu">
    <ul>
      <li>
        <a 
          href="/" 
          :class="{ active: isActive('/') }"
        >
          <span class="las la-igloo"></span>
          <span>Painel</span>
        </a>
      </li>
      <li>
        <a 
          href="/usuarios" 
          :class="{ active: isActive('/usuarios') }" 
        >
          <span class="las la-users"></span>
          <span>Usuários</span>
        </a>
      </li>
      <li>
        <a 
          href="/categorias"
          :class="{ active: isActive('/categorias') }"
        >
          <span class="las la-clipboard-list"></span>
          <span>Categorias</span>
        </a>
      </li>
      <li>
        <a 
          href="/restaurantes"
          :class="{ active: isActive('/restaurantes') }"
        >
          <span class="las la-shopping-bag"></span>
          <span>Restaurantes</span>
        </a>
      </li>
      <li>
        <a 
          href="/banners"
          :class="{ active: isActive('/banners') }"
        >
          <span class="las la-receipt"></span>
          <span>Banners </span>
        </a>
      </li>
      <li>
        <a 
          href="/planos"
          :class="{ active: isActive('/planos') }"
        >
          <span class="las la-clipboard-list"></span>
          <span>Planos </span>
        </a>
      </li>
      <li>
        <a 
          href="/perfil"
          :class="{ active: isActive('/perfil') }"
        >
          <span class="las la-user-circle"></span>
          <span>Perfil </span>
        </a>
      </li>
    </ul>
  </div>
</div>
