import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/home/home.vue";
import UsuariosView from "../views/users/usuarios.vue";
import CategoriasView from "../views/categorias/categorias.vue";
import RestaurantesView from "../views/restaurantes/restaurantes.vue";
import BannersView from "../views/banners/banners.vue";
import PlanosView from "../views/planos/planos.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: UsuariosView,
  },
  {
    path: "/categorias",
    name: "categorias",
    component: CategoriasView,
  },
  {
    path: "/restaurantes",
    name: "restaurantes",
    component: RestaurantesView,
  },
  {
    path: "/banners",
    name: "banners",
    component: BannersView,
  },
  {
    path: "/planos",
    name: "planos",
    component: PlanosView,
  },
  {
    path: "/perfil",
    name: "perfil",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
