<div class="usuarios-grid">
  <div class="projects">
    <div class="card">
      <div class="card-header">
        <h3>Banners</h3>
      </div>

      <div class="card-body">
        <div class="table-responsive">
          <table width="100%">
            <thead>
              <tr>
                <th>Restaurante</th>
                <th>Campanha</th>
                <th>Página</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Restaurante do João</td>
                <td>Marketing</td>
                <td>Home</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
                <td></td>
              </tr>
              <tr>
                <td>MC Donalds</td>
                <td>Semanal</td>
                <td>Home</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Recanto do Gaucho</td>
                <td>Brand</td>
                <td>Restaurantes</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Habib's</td>
                <td>Brand</td>
                <td>Blog Início</td>
                <td>
                  <span class="status purple"></span>
                  revisão
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Boi Dourado</td>
                <td>Brand</td>
                <td>Blog Artigo</td>
                <td>
                  <span class="status pink"></span>
                  ativo
                </td>
                <td></td>
              </tr>
              <tr>
                <td>Mineiro</td>
                <td>Mensal</td>
                <td>Restaurantes</td>
                <td>
                  <span class="status orange"></span>
                  pendente
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
