import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content" }

export function render(_ctx, _cache) {
  const _component_navigation = _resolveComponent("navigation")
  const _component_cabecalho = _resolveComponent("cabecalho")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_navigation),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_cabecalho),
      _createElementVNode("main", null, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}