import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Navigation",
  setup() {
    const companyName = ref("Guia Grande ABC");

    const route = useRoute();

    const isActive = (path: any) => {
      return route.path === path;
    };

    return {
      companyName,
      isActive,
    };
  },
});
